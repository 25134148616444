import {
  Users as UsersIcon,
  TrendingUp,
  Activity,
  Columns,
  Tool,
  LogOut,
  Calendar,
} from "react-feather";
import { admin, player } from "../../data/roles";
import Cookies from "js-cookie";
export const menu = {
  [admin]: [
    {
      href: "/users",
      icon: UsersIcon,
      title: "Zawodnicy",
      display: true,
    },
    {
      href: "/performance-results",
      icon: TrendingUp,
      title: "Wyniki",
      display: true,
    },
    {
      href: "/trainings",
      icon: Activity,
      title: "Treningi",
      display: true,
    },
    {
      href: "/permanent-reservations",
      icon: Calendar,
      title: "Stałe rezerwacje",
      display: true,
    },
    {
      href: "/training-types",
      icon: Columns,
      title: "Sekcje",
      display: true,
    },
    {
      href: "/performance-types",
      icon: Tool,
      title: "Ustawienia wyników",
      display: true,
    },
    {
      href: "/wyloguj",
      icon: LogOut,
      title: "Wyloguj",
      onClick: () => {
        Cookies.remove("token");
      },
      display: true,
    },
  ],
  [player]: [
    {
      href: "/trainings",
      icon: Activity,
      title: "Treningi",
      display: true,
    },
    {
      href: "/performance-results",
      icon: TrendingUp,
      title: "Wyniki",
      display: true,
    },
    {
      href: "/settings",
      icon: Tool,
      title: "Ustawienia",
      display: localStorage.getItem("beta-tester") === "true",
    },
    {
      href: "/wyloguj",
      icon: LogOut,
      title: "Wyloguj",
      display: true,
      onClick: () => {
        Cookies.remove("token");
      },
    },
  ],
};
