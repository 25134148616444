import { Layout } from "react-admin";
import { HAAppBar } from "./AppBar";
import { HASidebar } from "./Sidebar";
// import MyAppBar from './MyAppBar';
// import MySidebar from './MySidebar';
// import MyMenu from './MyMenu';
// import MyNotification from './MyNotification';

const HALayout = (superProps) => {
  return (
    <Layout
      {...superProps}
      appBar={HAAppBar}
      sidebar={HASidebar}
      // menu={MyMenu}
      // notification={MyNotification}
    />
  );
};

export default HALayout;
