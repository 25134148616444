import React from "react";
import { Login } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import img from "./team_logo.png";
import { theme } from "../../theme";

const useStyles = makeStyles({
  card: {
    padding: 20,
    margin: 0,
    boxShadow: "0 5px 10px 3px rgba(0,0, 0, 0.5)",
  },
  main: {
    height: "100vh",
    background: "#9c27b0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  avatar: {
    background: `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    height: 140,
  },
  icon: { display: "none" },
});

export const LoginPage = () => {
  const classes = useStyles();
  return <Login theme={theme} classes={classes} />;
};
