import React from "react";
import "./Settings.css";
import Paper from "@material-ui/core/Paper";

export const Settings = () => {
  return (
    <Paper>
      <div id="settings-container">
        <strong
          style={{
            marginBottom: 20,
          }}
        >
          Integracja z Messengerem
        </strong>
        <div
          class="fb-login-button"
          data-width=""
          data-size="large"
          data-button-type="continue_with"
          data-layout="default"
          data-auto-logout-link="true"
          data-use-continue-as="true"
          data-onlogin={(data) => {
            console.log(data);
          }}
        ></div>
      </div>
    </Paper>
  );
};
