import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";

export const theme = createMuiTheme({
  typography: {
    fontFamily: ["Ubuntu", "Lato", "Roboto"],
  },
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: "#631970",
    },
  },
});
